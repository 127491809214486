/* eslint-disable sonarjs/no-collapsible-if */
<template>
  <div>
    <v-wait for="partner">
      <half-circle-spinner
        slot="waiting"
        style="display: block; margin: auto auto"
        :animation-duration="1000"
        :size="60"
        color="#3d4852"
      />
      <div>
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPageLoader"
          :color="loaderColor"
        ></loading>

        <template>
          <div v-if="showDaysLeftInPlan" class="bg-white w-full rounded border-solid border-grey-200 border mb-3 quicklink-wrapper">
            <div class="p-4 relative borderBottom">
              Your <b>{{planName}}</b> plan ends in <b>{{planEndInDays}} days</b>. <span v-if="upgradeButtonUrl"><a class="underline" target="_blank" :href="upgradeButtonUrl">Upgrade now</a> for a special offer.</span>
            </div>
          </div>
          <div class="bg-white w-full rounded border-solid border-grey-200 border mb-3 quicklink-wrapper">
            <div class="p-4 relative borderBottom flex justify-between">
              <p class="text-base text-gray-500 flex flex-row items-center font-bold">
                {{ $t('frontend_gd_dashboard_quick_links') }}
              </p>
              <div
                style="color: #fb5858"
                class="-mb-2 -my-1 text-2xl"
                v-tooltip.left-start="{
                  classes: 'video-walkthrough-tooltip',
                  content: 'Full Platform Walkthrough',
                }"
                @click="openVideoWalkthrough()"
              >
                <i class="fas fa-video cursor-pointer"></i>
              </div>
            </div>
            <div class="flex flex-row flex-wrap lg:flex-nowrap">
              <div
                v-for="(links, index) in quickLinks"
                :key="index"
                class="flex flex-col items-center content-between justify-between w-1/2 h-32 cursor-pointer sm:w-1/4 borderRight relative quicklink-list"

              >
                <div @click="redirectUrl(links.name)" class="absolute top-0 left-0 right-0 bottom-0"></div>
                <div class="flex flex-wrap content-end h-16">
                  <component :is="links.icon" class="h-10" />
                </div>
                <div class="flex flex-wrap content-start h-16">
                  <p
                    class="text-sm text-color font-semibold text-center mt-2"
                    v-html="links.name"
                  ></p>
                </div>
                <button
                  v-if="links.dropdownOptions"
                  class="absolute top-0 right-0 p-2 mt-2 mr-1 rounded-lg cursor-pointer quicklink-btn"
                  @click="toggleDropdown(index)"
                >
                  <component
                    :is="index === dropdownOpen ? 'CloseIcon' : 'DropdownIcon'"
                    class="w-4 h-4"
                  />
                </button>
                <transition name="fade">
                  <div v-if="index === dropdownOpen" class="absolute right-0 top-0 mt-10 mr-2 whitespace-nowrap bg-white rounded-lg text-base quicklink-dropdown">
                      <div
                        v-for="(option, index) in links.dropdownOptions"
                        :key="index"
                        class="px-4 py-2 hover:bg-grey-100 flex items-center"
                        @click="redirectDropdown(option.linkUrl)"
                      >
                          <span class="w-6 mr-2" style="color: #989AB3;">
                             <component :is="option.linkIcon" class="h-5" />
                          </span>
                          {{option.linkName}}
                      </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div>
            <div v-if="upgradeAdUrl">
              <div
              class="
                  flex
                  bg-white
                  shadow
                  sm:rounded-lg sm:overflow-hidden
                  mb-4
                  w-full
              "
              style="height: 30rem;"
              >
                  <div ref="dashboardIframeContainer" class="w-full relative" style="height: 30rem;">
                      <div class="p-4 w-full absolute" :class="isUpgradeAdLoading ? 'block' : 'hidden'" style="background-color: rgba(0, 0, 0, 0.2); height: 30rem;">
                          <half-circle-spinner
                          style="display: block; margin: 40px auto"
                          :animation-duration="1000"
                          :size="60"
                          color="#FD6481"
                          />
                      </div>
                      <iframe
                      frameborder="0"
                      :src="upgradeAdUrl"
                      class="w-full"
                      style="overflow-y: hidden; height: 30rem;"
                      @load="oniFrameload"
                      ></iframe>
                  </div>
              </div>
          </div>
          <div v-else-if="dashboardAdData.is_dashboard_ad_enabled">
              <div
              class="
                  flex
                  bg-white
                  shadow
                  sm:rounded-lg sm:overflow-hidden
                  mb-4
                  h-128
              "
              v-if="dashboardAdData.dashboard_setting_type == 1 && dashboardAdLinks.length > 0"
              >
              <div
                  class="w-1/2 flex flex-row p-4 justify-center"
                  v-if="
                  dashboardVideoIframe &&
                  dashboardVideoIframe != '' &&
                  dashboardVideoIframe.includes('groovevideo')
                  "
              >
                  <div class="flex flex-col w-full" style="max-width: 640px">
                  <div class="flex flex-col justify-center">
                      <iframe
                      v-resize="{ log: false }"
                      :src="dashboardVideoIframe"
                      class="w-full"
                      frameborder="0"
                      style="min-height: 24rem"
                      >
                      </iframe>
                  </div>
                  </div>
              </div>
              <div class="w-1/2 flex flex-row justify-center h-128 p-2" v-else>
                  <div class="flex flex-row justify-center w-full">
                  <div
                      class="relative h-120 w-full"
                      style="padding-top: 56.25%; max-width: 640px"
                  >
                      <iframe
                      v-resize="{ log: false }"
                      :src="
                          'https://youtube.com/embed/' +
                          dashboardAdData.dashboard_ad_video_id +
                          '?rel=0&amp;autoplay=1&mute=1'
                      "
                      class="absolute inset-0 w-full h-120"
                      frameborder="0"
                      >
                      </iframe>
                  </div>
                  </div>
              </div>
              <div class="w-1/2 flex flex-col justify-center">
                  <h3
                  class="
                      text-lg
                      font-extrabold
                      text-grey-900
                      tracking-tight
                      text-center
                  "
                  >
                  {{ this.dashboardAdData.dashboard_ad_heading }}
                  </h3>
                  <p class="text-center">
                  {{ this.dashboardAdData.dashboard_ad_description }}
                  </p>
                  <div class="px-8 flex flex-row flex-wrap w-full mt-4">
                  <div
                      class="w-1/2 p-4"
                      v-for="(link, index) in dashboardAdLinks"
                      :key="index"
                  >
                      <div
                      class="
                          cursor-pointer
                          text-center
                          bg-white
                          rounded-lg
                          overflow-hidden
                          w-full
                          flex flex-col
                          justify-center
                      "
                      :class="{
                          'border border-grey-500 shadow':
                          dashboardAdData.dashboard_ad_type == 0,
                      }"
                      @click="openUrlInNewTab(link.link)"
                      style="height: 6rem"
                      >
                      <dd
                          v-if="dashboardAdData.dashboard_ad_type == 0"
                          class="mt-1 text-3xl font-semibold text-grey-900"
                      >
                          {{ link.text }}
                      </dd>
                      <dd v-else>
                          <img
                          :src="link.image"
                          alt=""
                          class="w-full object-cover object-center"
                          />
                      </dd>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
              <div
              class="
                  flex
                  bg-white
                  shadow
                  sm:rounded-lg sm:overflow-hidden
                  mb-4
                  h-128
                  w-full
              "
              v-else-if="dashboardAdData.dashboard_setting_type == 2 && dashboardAdData.dashboard_ad_iframe_url != ''"
              >
                  <div ref="dashboardIframeContainer" class="w-full h-full">
                      <iframe
                      frameborder="0"
                      :src="dashboardAdData.dashboard_ad_iframe_url"
                      class="w-full h-128"
                      style="overflow-y: hidden"
                      @load="oniFrameload"
                      ></iframe>
                  </div>
              </div>
          </div>
      </div>
        </template>

        <div class="flex flex-col lg:flex-row justify-between gap-6">
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardChart
              title="GrooveSell"
              :chartSeries="grooveSellSeries"
              :chartsOption="chartOptions"
              :sellNumber="sellData.selected_tab_data"
              :activeItem="sellActive"
              @grooveSell="grooveSell"
              @redirectUrl="redirectUrl"
              :errResponse="sellErr"
              :hasUsed="hasUsedGroovesell"
              :hasData="hasGroovesellData"
              :dataLoaded="hasGroovesellDataLoaded"
            >
            </dashboardChart>
          </div>
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardTable
              title="GroovePages"
              :tableCol="pagesColName"
              :tableHeadings="pagesHeadings"
              :tableData="pageData"
              :filterVisible="true"
              :activeItem="pageActive"
              @groovePage="groovePage"
              @redirectUrl="redirectUrl"
              :errResponse="pageErr"
              :hasUsed="hasUsedGroovepages"
              :hasData="hasGroovepagesData"
              :dataLoaded="hasGroovepagesDataLoaded"
            ></dashboardTable>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row justify-between gap-6">
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardTable
              title="GrooveAffiliate"
              :tableData="promoData"
              :tableCol="affiliatesColName"
              :tableHeadings="affiliateHeadings"
              :filterVisible="true"
              :activeItem="promoActive"
              @groovePromo="groovePromo"
              @redirectUrl="redirectUrl"
              :errResponse="promoErr"
              :hasUsed="hasUsedGrooveaffiliate"
              :hasData="hasGrooveaffiliateData"
              :dataLoaded="hasGrooveAffiliateDataLoaded"
            ></dashboardTable>
          </div>
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardTable
              title="GrooveMail"
              :tableCol="mailColName"
              :tableHeadings="mailHeadings"
              :tableData="mailData"
              @grooveMail="grooveMail"
              @redirectUrl="redirectUrl"
              :activeItem="mailActive"
              :filterVisible="true"
              :errResponse="mailErr"
              :hasUsed="hasUsedGroovemail"
              :hasData="hasGroovemailData"
              :dataLoaded="hasGroovemailDataLoaded"
            ></dashboardTable>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row justify-between gap-6">
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardChart
              title="GrooveMember"
              :chartSeries="grooveMemSeriers"
              :chartsOption="chartOptions"
              :memberNumber="memberNumber"
              :activeItem="memActive"
              @grooveMember="grooveMember"
              @redirectUrl="redirectUrl"
              :errResponse="memmberErr"
              :hasUsed="hasUsedGroovemember"
              :hasData="hasGroovememberData"
              :dataLoaded="hasGroovememberDataLoaded"
            ></dashboardChart>
          </div>
          <div class="bg-white my-3 border-solid border-grey-200 border rounded w-full">
            <dashboardTable
              title="GrooveVideo"
              :tableData="videosData"
              :tableCol="videoColName"
              :tableHeadings="videoHeadings"
              :activeItem="vidActive"
              :errResponse="videoErr"
              @grooveVideo="grooveVideo"
              @redirectUrl="redirectUrl"
              :hasUsed="hasUsedGroovevideo"
              :hasData="hasGroovevideoData"
              :dataLoaded="hasGroovevideoDataLoaded"
            ></dashboardTable>
          </div>
        </div>

        <!-- quickslinks popup starts -->
        <gd-modal ref="quickLink" name="quickLink" title="QuickLinks Settings">
          <div class="flex flex-col p-6">
            <p class="text-md mb-2">
              {{ $t('frontend_dashboard_select_quicklinks') }}:
            </p>
            <div class="flex flex-row flex-wrap">
              <div
                class="w-1/2 pr-4 mb-3"
                v-for="(item, index) in quickLinks"
                :key="index"
              >
                <span class="text-sm">{{ item.name }}</span>
                <gd-toggle v-model="item.visible" class="float-right" />
              </div>
            </div>
            <div class="flex flex-row items-center justify-center mt-5">
              <gd-button
                size="auto"
                primary
                class="px-8 py-4 mr-2"
                @click="submitquickLinks"
                >{{ $t('frontend_done') }}</gd-button
              >
              <gd-button
                size="auto"
                class="px-8 py-4 mr-2"
                @click="closequicklinks"
                >{{ $t('frontend_cancel') }}</gd-button
              >
            </div>
          </div>
        </gd-modal>

        <account-modal :open="editingUserDetails" @close="closeAccountModal" />
        <iframe-modal
          :url="videoWalkthroughUrl"
          title="Full Platform Walkthrough"
          :open="videoWalkthroughOpen"
          @close="videoWalkthroughOpen = false"
       />
        <iframe-modal
          :url="partnerProgramUrl"
          title="Groove Partner Program"
          :open="showPartnerProgram"
          @close="showPartnerProgram = 0"
        />
      </div>
    </v-wait>

    <portal to="headerContent">
      <div class="flex flex-row">
        <div class="flex items-center flex-row">
          <template>
            <button
              class="flex items-center w-auto h-8 p-4 mr-4 bg-white border rounded-lg shadow heading-sm focus:outline-none hover:shadow-lg focus:shadow-lg hover:bg-pink-dark focus:bg-black text-dark hover:text-white border-dark focus:border-black hover:border-transparent"
              @click="showPartnerProgram = 1"
            >
              <span
                class="relative flex items-center justify-center w-full h-full"
                >{{
                  $t('frontend_sites_side_bar_groove_affiliate_program')
                }}</span
              >
            </button>
            <gd-button
              size="auto"
              v-show="upgradeButtonUrl"
              class="p-4 mr-4"
              style="animation:2s ease 0s infinite normal none running ps-pulse2"
              @click="goToUpgradePage"
            >
              {{ upgrade_button_text_localized }}
            </gd-button>
          </template>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import AccountModal from '@groovepages/gd-ui-kit/components/AccountModal';
import auth from '@groovepages/gd-lib/auth';
import dashboardChart from './dashboard-charts';
import dashboardTable from './dashboard-table';
import themeConfig from '@/theme/themeConfig';
import GroovePagesLogo from '@/assets/images/icons/quicklinks/groovepages-logo-small.svg';
import GrooveSellLogo from '@/assets/images/icons/quicklinks/groovesell-logo-small.svg';
import GrooveMailLogo from '@/assets/images/icons/quicklinks/groovemail-logo-small.svg';
import GrooveMemberLogo from '@/assets/images/icons/quicklinks/groovemember-logo-small.svg';
import GrooveVideoLogo from '@/assets/images/icons/quicklinks/groovevideo-logo-small.svg';
import GrooveBlogLogo from '@/assets/images/icons/quicklinks/grooveblog-logo-small.svg';
import GrooveKartLogo from '@/assets/images/icons/quicklinks/groovekart-logo-small.svg';
import GrooveWebinarLogo from '@/assets/images/icons/quicklinks/groovewebinar-logo-small.svg';
import DropdownIcon from '@/assets/images/icons/quicklinks/dropdown.svg';
import CloseIcon from '@/assets/images/icons/close.svg';
import IframeModal from '@/components/IframeModal';

export default {
  middleware: 'auth',
  components: {
    Loading,
    dashboardChart,
    dashboardTable,
    AccountModal,
    IframeModal,
    GroovePagesLogo,
    GrooveSellLogo,
    GrooveMailLogo,
    GrooveMemberLogo,
    GrooveVideoLogo,
    GrooveBlogLogo,
    GrooveKartLogo,
    GrooveWebinarLogo,
    DropdownIcon,
    CloseIcon,
  },
  data() {
    return {
      upgradeAdUrl: 0,
      showDaysLeftInPlan: 0,
      planEndInDays: '',
      planName: '',
      isUpgradeAdLoading: false,
      upgradeButtonUrl: 0,
      upgrade_button_text_localized: '',
      dashboardAdData: {
        is_dashboard_ad_enabled: 0,
        dashboard_ad_video_provider: '',
        dashboard_ad_video_id: '',
        dashboard_ad_paid_video_provider: '',
        dashboard_ad_paid_video_id: '',
        dashboard_ad_heading: '',
        dashboard_ad_description: '',
        dashboard_ad_type: 0,
        dashboard_ad_link1_image: '',
        dashboard_ad_link1_title: '',
        dashboard_ad_link1: '',
        dashboard_ad_link1_display: 0,
        dashboard_ad_link2_image: '',
        dashboard_ad_link2_title: '',
        dashbaord_ad_link2: '',
        dashboard_ad_link2_display: 0,
        dashboard_ad_link3_image: '',
        dashboard_ad_link3_title: '',
        dashboard_ad_link3: '',
        dashboard_ad_link3_display: 0,
        dashboard_ad_link4_image: '',
        dashboard_ad_link4_title: '',
        dashboard_ad_link4: '',
        dashboard_ad_link4_display: 0,
        dashboard_ad_link5_image: '',
        dashboard_ad_link5_title: '',
        dashboard_ad_link5: '',
        dashboard_ad_link5_display: 0,
        dashboard_ad_link6_image: '',
        dashboard_ad_link6_title: '',
        dashboard_ad_link6: '',
        dashboard_ad_link6_display: 0,
        dashboard_ad_link7_image: '',
        dashboard_ad_link7_title: '',
        dashboard_ad_link7: '',
        dashboard_ad_link7_display: 0,
        dashboard_ad_link8_image: '',
        dashboard_ad_link8_title: '',
        dashboard_ad_link8: '',
        dashboard_ad_link8_display: 0,
      },
      dropdownOpen: null,
      videoWalkthroughOpen: false,
      quickLinks: [
        {
          name: 'GroovePages<br />GrooveFunnels',
          icon: GroovePagesLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Add New Site',
              linkUrl: '/groovepages?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveSell',
          icon: GrooveSellLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Funnels and Products',
              linkUrl: '/groovesell/funnels',
              linkIcon: () => import("@/assets/images/quicklinks/funnel.svg"),
            },
            {
              linkName: 'Reporting',
              linkUrl: '/groovesell/transactions',
              linkIcon: () => import("@/assets/images/quicklinks/reporting.svg"),
            },
            {
              linkName: 'Manage Affiliates',
              linkUrl: '/groovesell/affiliates',
              linkIcon: () => import("@/assets/images/quicklinks/manage-affiliates.svg"),
            },
            {
              linkName: 'Affiliate Performance',
              linkUrl: '/groovesell/affiliates-performance',
              linkIcon: () => import("@/assets/images/quicklinks/affiliate-performance.svg"),
            },
            {
              linkName: 'Customers',
              linkUrl: '/groovesell/customers',
              linkIcon: () => import("@/assets/images/quicklinks/customers.svg"),
            },
            {
              linkName: 'Checkout Forms',
              linkUrl: '/groovesell/checkout-links',
              linkIcon: () => import("@/assets/images/quicklinks/checkout-forms.svg"),
            },
            {
              linkName: 'Tracking Links',
              linkUrl: '/groovesell/tracking',
              linkIcon: () => import("@/assets/images/quicklinks/tracking-links.svg"),
            },
            {
              linkName: 'Affiliate Portals And Tools',
              linkUrl: '/groovesell/funnel-resources',
              linkIcon: () => import("@/assets/images/quicklinks/tracking-links.svg"),
            },
            {
              linkName: 'New Funnel',
              linkUrl: '/groovesell/funnels?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveMail',
          icon: GrooveMailLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Broadcasts',
              linkUrl: '/groovemail/broadcast',
              linkIcon: () => import("@/assets/images/quicklinks/broadcasts.svg"),
            },
            {
              linkName: 'Sequences',
              linkUrl: '/groovemail/sequences',
              linkIcon: () => import("@/assets/images/quicklinks/sequences.svg"),
            },
            {
              linkName: 'Lists',
              linkUrl: '/groovemail/lists',
              linkIcon: () => import("@/assets/images/quicklinks/lists.svg"),
            },
            {
              linkName: 'Contacts',
              linkUrl: '/groovemail/leads',
              linkIcon: () => import("@/assets/images/quicklinks/leads.svg"),
            },
            {
              linkName: 'Automations',
              linkUrl: '/groovemail/automations',
              linkIcon: () => import("@/assets/images/quicklinks/automations.svg"),
            },
            {
              linkName: 'Forms',
              linkUrl: '/groovemail/forms',
              linkIcon: () => import("@/assets/images/quicklinks/forms.svg"),
            },
            {
              linkName: 'Professional Email Templates',
              linkUrl: '/groovemail/my-templates?type=professionally-designed-templates',
              linkIcon: () => import("@/assets/images/quicklinks/email-templates.svg"),
            },
            {
              linkName: 'New Broadcast',
              linkUrl: '/groovemail/campaign/create',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
            {
              linkName: 'New Sequence',
              linkUrl: '/groovemail/sequences?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveMember',
          icon: GrooveMemberLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Membership',
              linkUrl: '/groovemember/memberships',
              linkIcon: () => import("@/assets/images/quicklinks/membership.svg"),
            },
            {
              linkName: 'Courses',
              linkUrl: '/groovemember/courses',
              linkIcon: () => import("@/assets/images/quicklinks/courses.svg"),
            },
            {
              linkName: 'Portals',
              linkUrl: '/groovemember/portals',
              linkIcon: () => import("@/assets/images/quicklinks/portals.svg"),
            },
            {
              linkName: 'Files',
              linkUrl: '/groovemember/memberships/files',
              linkIcon: () => import("@/assets/images/quicklinks/files.svg"),
            },
            {
              linkName: 'Instructors',
              linkUrl: '/groovemember/instructors',
              linkIcon: () => import("@/assets/images/quicklinks/instructors.svg"),
            },
            {
              linkName: 'Analytics',
              linkUrl: '/groovemember/analytics/comming-soon',
              linkIcon: () => import("@/assets/images/quicklinks/analytics.svg"),
            },
            {
              linkName: 'New Membership',
              linkUrl: '/groovemember/memberships?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
            {
              linkName: 'New Course',
              linkUrl: '/groovemember/courses?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
            {
              linkName: 'New File',
              linkUrl: '/groovemember/memberships/files?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveVideo',
          icon: GrooveVideoLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Library',
              linkUrl: '/groovevideo/videos',
              linkIcon: () => import("@/assets/images/quicklinks/library.svg"),
            },
            {
              linkName: 'Categories',
              linkUrl: '/groovevideo/categories',
              linkIcon: () => import("@/assets/images/quicklinks/categories.svg"),
            },
            {
              linkName: 'Storage Settings',
              linkUrl: '/groovevideo/settings#ownStorage',
              linkIcon: () => import("@/assets/images/quicklinks/storage.svg"),
            },
            {
              linkName: 'Encoder Settings',
              linkUrl: '/groovevideo/settings#encoding',
              linkIcon: () => import("@/assets/images/quicklinks/encoder.svg"),
            },
            {
              linkName: 'Analytics',
              linkUrl: '/groovevideo/analytics',
              linkIcon: () => import("@/assets/images/quicklinks/analytics.svg"),
            },
            {
              linkName: 'New Video',
              linkUrl: '/groovevideo/video/uploadvideo',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveBlog',
          icon: GrooveBlogLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'Add New Blog',
              linkUrl: '/grooveblog?new=modal',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
        {
          name: 'GrooveKart',
          icon: GrooveKartLogo,
          visible: true,
        },
        {
          name: 'GrooveWebinar',
          icon: GrooveWebinarLogo,
          visible: true,
          isOpen: false,
          dropdownOptions: [
            {
              linkName: 'My Webinars',
              linkUrl: '/groovewebinar/webinar/schedule',
              linkIcon: () => import("@/assets/images/quicklinks/my-webinars.svg"),
            },
            {
              linkName: 'Invited Webinars',
              linkUrl: '/groovewebinar/webinar/invite',
              linkIcon: () => import("@/assets/images/quicklinks/invited-webinar.svg"),
            },
            {
              linkName: 'Surveys',
              linkUrl: '/groovewebinar/webinar/survey',
              linkIcon: () => import("@/assets/images/quicklinks/surveys.svg"),
            },
            {
              linkName: 'Polls',
              linkUrl: '/groovewebinar/webinar/poll',
              linkIcon: () => import("@/assets/images/quicklinks/polls.svg"),
            },
            {
              linkName: 'Canned responses',
              linkUrl: '/groovewebinar/settings',
              linkIcon: () => import("@/assets/images/quicklinks/canned-response.svg"),
            },
            {
              linkName: 'Analytics',
              linkUrl: '/groovewebinar/webinar/analytics',
              linkIcon: () => import("@/assets/images/quicklinks/webinar-analytics.svg"),
            },
            {
              linkName: 'New Webinar',
              linkUrl: '/groovewebinar/webinar/schedule/create',
              linkIcon: () => import("@/assets/images/quicklinks/add-item.svg"),
            },
          ]
        },
      ],
      insights: [
        { name: 'Overview' },
        { name: 'GroovePages' },
        { name: 'GrooveSell' },
        { name: 'GrooveMail' },
        { name: 'GrooveMember' },
      ],
      chartsKey: 1,
      iframeHeight: '200px',
      dashboardIframeHeight: '200px',
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
      editingUserDetails: false,
      recentWork: [
        {
          topic: 'Online course marketing site',
          time: '12 min ago',
          category: 'GroovePages',
        },
        {
          topic: 'Online course marketing site',
          time: '12 min ago',
          category: 'GrooveMail',
        },
        {
          topic: 'Online course marketing site',
          time: '12 min ago',
          category: 'GrooveSell',
        },
      ],
      videosData: [],
      sellData: [],
      mailData: [],
      memberData: [],
      promoData: [],
      pageData: [],
      fromDate: moment().subtract(7, 'days').format('L'),
      toDate: moment().format('L'),
      diffDays: 7,
      videoColName: ['video_name', 'plays', 'completion_rate'],
      videoHeadings: {
        video_name: () => this.$t('frontend_gd_dashboard_videos'),
        plays: () => this.$t('frontend_gd_dashboard_plays'),
        completion_rate: () => this.$t('frontend_gd_dashboard_completionrate'),
      },
      pagesColName: ['site_name', 'Visits', 'UniqueVisitors', 'BounceRate'],
      pagesHeadings: {
        site_name: () => this.$t('frontend_gd_dashboard_site_name'),
        Visits: () => this.$t('frontend_gd_dashboard_visits'),
        UniqueVisitors: () => this.$t('frontend_gd_dashboard_unique_visitors'),
        BounceRate: () => this.$t('frontend_gd_dashboard_bounce_rate'),
      },
      mailColName: ['broadcast_name', 'recipients', 'opens', 'clicks'],
      mailHeadings: {
        broadcast_name: () => this.$t('frontend_gd_dashboard_broadcast_name'),
        recipients: () => this.$t('frontend_gd_dashboard_recepients'),
        opens: () => this.$t('frontend_gd_dashboard_opens'),
        clicks: () => this.$t('frontend_gd_dashboard_clicks'),
      },
      affiliatesColName: ['promo_name', 'optins', 'uniques', 'commissions'],
      affiliateHeadings: {
        promo_name: () => this.$t('frontend_gd_dashboard_promo_name'),
        optins: () => this.$t('frontend_gd_dashboard_optins'),
        uniques: () => this.$t('frontend_gd_dashboard_uniques'),
        commissions: () => this.$t('frontend_gd_dashboard_commisions'),
      },
      memberNumber: [],
      grooveMemSeriers: [
        {
          name: '',
          data: [],
        },
      ],
      // for grooveSell chart
      sellNumbers: [],
      grooveSellSeries: [
        {
          name: '',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            startingShape: 'flat',
            endingShape: 'flat',
          },
        },
        title: {
          text: '',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'],
            opacity: 0.5,
          },
          strokeDashArray: 8,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          showAlways: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
      /* filter active state */
      sellActive: 0,
      memActive: 0,
      vidActive: 0,
      mailActive: 0,
      promoActive: 0,
      pageActive: 0,
      /* err response */
      videoErr: true,
      pageErr: true,
      promoErr: true,
      mailErr: true,
      memmberErr: true,
      sellErr: true,
      hasUsedGroovesell: 0,
      hasUsedGroovepages: 0,
      hasUsedGrooveaffiliate: 0,
      hasUsedGroovemail: 0,
      hasUsedGroovemember: 0,
      hasUsedGroovevideo: 0,
      hasGroovesellData: 0,
      hasGroovepagesData: 0,
      hasGrooveaffiliateData: 0,
      hasGroovemailData: 0,
      hasGroovememberData: 0,
      hasGroovevideoData: 0,
      showPartnerProgram: 0,
      hasGroovesellDataLoaded: 0,
      hasGroovememberDataLoaded: 0,
      hasGroovepagesDataLoaded: 0,
      hasGrooveAffiliateDataLoaded: 0,
      hasGroovemailDataLoaded: 0,
      hasGroovevideoDataLoaded: 0,
    };
  },

  metaInfo() {
    return { title: 'Overview' };
  },

  computed: {
    ...mapGetters(['metaData', 'user']),
    partnerProgramUrl() {
      return 'https://groove.cm/butterfly-jv';
    },
    videoWalkthroughUrl() {
      return 'https://groove.cm/full-platform-walkthrough';
    },
    isPlatinum()
    {
        if(typeof auth.authenticatedUser().plan !== 'undefined' && auth.authenticatedUser().plan[0].toLowerCase().includes("platinum"))
        {
            return true;
        }
        return false;
    },
    dashboardVideoIframe() {
      let dashboardVideoIframe = '';
      if (
        this.dashboardAdData &&
        this.dashboardAdData.is_dashboard_ad_enabled
      ) {
        if (
          this.dashboardAdData.dashboard_ad_video_provider &&
          this.dashboardAdData.dashboard_ad_video_id
        ) {
          if (
            this.dashboardAdData.dashboard_ad_video_provider == 'groovevideo'
          ) {
            dashboardVideoIframe =
              this.dashboardAdData.dashboard_ad_video_id.replace(
                '/groovevideo/videopage/',
                '/groovevideo/video/'
              );
          } else {
            dashboardVideoIframe = `https://youtube.com/embed/${this.dashboardAdData.dashboard_ad_video_id}?rel=0&amp;autoplay=1&mute=1`;
          }
        }

        if (
          !this.$store.getters.isLiteUser &&
          this.dashboardAdData.dashboard_ad_paid_video_provider &&
          this.dashboardAdData.dashboard_ad_paid_video_id
        ) {
          if (
            this.dashboardAdData.dashboard_ad_paid_video_provider ==
            'groovevideo'
          ) {
            dashboardVideoIframe =
              this.dashboardAdData.dashboard_ad_paid_video_id.replace(
                '/groovevideo/videopage/',
                '/groovevideo/video/'
              );
          } else {
            dashboardVideoIframe = `https://youtube.com/embed/${this.dashboardAdData.dashboard_ad_paid_video_id}?rel=0&amp;autoplay=1&mute=1`;
          }
        }
      }

      return dashboardVideoIframe;
    },


    dashboardGrooveVideo() {
      let grooveVideoVideo = '';
      if (this.dashboardVideoIframe.includes('groovevideo')) {
        const res = this.dashboardVideoIframe.split('/');
        const pos = res.indexOf('video');
        grooveVideoVideo = `<groovevideo-widget id="${
          res[pos + 1]
        }" permalink="${res[pos + 2]}"></groovevideo-widget>`;
      }
      return grooveVideoVideo;
    },

    metadata() {
      return {
        quickLinks: [
          {
            name: 'GroovePages<br />GrooveFunnels',
            icon: 'New-icon.png',
            visible: true,
          },
          {
            name: 'GrooveSell',
            icon: 'chart-bar.png',
            visible: true,
          },
          {
            name: 'GrooveMail',
            icon: 'mail-icon.png',
            visible: true,
          },
          {
            name: 'GrooveMember',
            icon: 'Membership-Icon.png',
            visible: true,
          },
          {
            name: 'GrooveVideo',
            icon: 'Video-Icon.png',
            visible: true,
          },
          {
            name: 'GrooveBlog',
            icon: 'pen-square.png',
            visible: true,
          },
          {
            name: 'GrooveKart',
            icon: 'cart-plus.png',
            visible: true,
          },
          {
            name: 'GrooveWebinar',
            icon: 'webinar-icon.png',
            visible: true,
          },
        ],
        insights: [
          { name: 'Overview' },
          { name: 'GroovePages' },
          { name: 'GrooveSell' },
          { name: 'GrooveMail' },
          { name: 'GrooveMember' },
        ],
      };
    },

    dashboardAdLinks() {
      const dashboardAdLinks = [];

      if (
        this.dashboardAdData &&
        this.dashboardAdData.is_dashboard_ad_enabled
      ) {
        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link1_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link1_image)) &&
          this.dashboardAdData.dashboard_ad_link1
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link1_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link1_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link1_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link1_title,
              link: this.dashboardAdData.dashboard_ad_link1,
              image: this.dashboardAdData.dashboard_ad_link1_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link2_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link2_image)) &&
          this.dashboardAdData.dashboard_ad_link2
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link2_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link2_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link2_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link2_title,
              link: this.dashboardAdData.dashboard_ad_link2,
              image: this.dashboardAdData.dashboard_ad_link2_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link3_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link3_image)) &&
          this.dashboardAdData.dashboard_ad_link3
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link3_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link3_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link3_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link3_title,
              link: this.dashboardAdData.dashboard_ad_link3,
              image: this.dashboardAdData.dashboard_ad_link3_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link4_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link4_image)) &&
          this.dashboardAdData.dashboard_ad_link4
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link4_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link4_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link4_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link4_title,
              link: this.dashboardAdData.dashboard_ad_link4,
              image: this.dashboardAdData.dashboard_ad_link4_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link5_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link5_image)) &&
          this.dashboardAdData.dashboard_ad_link5
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link5_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link5_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link5_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link5_title,
              link: this.dashboardAdData.dashboard_ad_link5,
              image: this.dashboardAdData.dashboard_ad_link5_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link6_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link6_image)) &&
          this.dashboardAdData.dashboard_ad_link6
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link6_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link6_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link6_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link6_title,
              link: this.dashboardAdData.dashboard_ad_link6,
              image: this.dashboardAdData.dashboard_ad_link6_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link7_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link7_image)) &&
          this.dashboardAdData.dashboard_ad_link7
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link7_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link7_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link7_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link7_title,
              link: this.dashboardAdData.dashboard_ad_link7,
              image: this.dashboardAdData.dashboard_ad_link7_image,
            });
          }
        }

        if (
          ((this.dashboardAdData.dashboard_ad_type == 0 &&
            this.dashboardAdData.dashboard_ad_link8_title) ||
            (this.dashboardAdData.dashboard_ad_type == 1 &&
              this.dashboardAdData.dashboard_ad_link8_image)) &&
          this.dashboardAdData.dashboard_ad_link8
        ) {
          if (
            this.dashboardAdData.dashboard_ad_link8_display == 0 ||
            (this.dashboardAdData.dashboard_ad_link8_display == 1 &&
              this.$store.getters.isLiteUser) ||
            (this.dashboardAdData.dashboard_ad_link8_display == 2 &&
              !this.$store.getters.isLiteUser)
          ) {
            dashboardAdLinks.push({
              text: this.dashboardAdData.dashboard_ad_link8_title,
              link: this.dashboardAdData.dashboard_ad_link8,
              image: this.dashboardAdData.dashboard_ad_link8_image,
            });
          }
        }
      }

      return dashboardAdLinks;
    },

    hasMetaDataObject () {
      return !Array.isArray(this.metaData);
    },
  },

  async created() {
    this.$wait.start('partner');

    await axios
      .get(`groovesell/dashboard-setting`)
      .then((data) => {
        if (data.status) {
          this.dashboardAdData = data.data.data.value;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`groovesell/show-member-upgrade-ad/${auth.authenticatedUser().id}`)
      .then((data) => {
        this.upgradeAdUrl = data.data.upgrade_ad_url;
        this.upgradeButtonUrl = data.data.upgrade_button_url;
        this.showDaysLeftInPlan = data.data.show_days_left_in_plan;
        this.planEndInDays = data.data.plan_end_in_days;
        this.planName = data.data.plan_name;


        this.upgrade_button_text_localized = data.data.upgrade_button_text[this.$root.$i18n.locale ?? 'en'];

        if(this.upgradeAdUrl)
        {
            this.isUpgradeAdLoading = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`groovedigital/get-last-seen-jv-page`)
      .then((response) => {
        this.showPartnerProgram = response.data.show_partner_program;
        this.videoWalkthroughOpen = response.data.show_partner_program;
      }).catch(() => {});

    this.$wait.end('partner');

    if (this.showPartnerProgram == 0) {
      this.getGrooveSell(0);
      this.getGrooveMember(0);
      this.getGroovePage(0);
      this.getGroovePromos(0);
      this.getGrooveMail(0);
      this.getVideoData(0);
    }
  },
  methods: {
    openUrlInNewTab(link) {
      const win = window.open(link, '_blank');
      win.focus();
    },

    goToUpgradePage() {
      window.open(this.upgradeButtonUrl, '_blank');
    },

    showLoader() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },

    hideLoader() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

    editUserDetails() {
      this.editingUserDetails = true;
    },

    closeAccountModal() {
      this.editingUserDetails = false;
    },

    redirectUrl(linkType) {
      console.log(linkType);
      if (
        linkType == this.$t('frontend_gd_dashboard_email_marketing') ||
        linkType == 'GrooveMail'
      ) {
        window.location = '/groovemail/';
      } else if (
        linkType == this.$t('frontend_gd_dashboard_manage_products') ||
        linkType == 'GrooveSell'
      ) {
        window.location = '/groovesell/';
      } else if (
        linkType == this.$t('frontend_gd_dashboard_manage_sites') ||
        linkType == 'GroovePages' ||
        linkType == 'GroovePages<br />GrooveFunnels'
      ) {
        window.location = '/groovepages/';
      } else if (
        linkType == this.$t('frontend_gd_dashboard_manage_videos') ||
        linkType == 'GrooveVideo'
      ) {
        window.location = '/groovevideo/';
      } else if (
        linkType == this.$t('frontend_gd_dashboard_manage_memberships') ||
        linkType == 'GrooveMember'
      ) {
        window.location = '/groovemember/';
      } else if (linkType == 'GrooveBlog') {
        window.location = '/grooveblog/';
      } else if (linkType == 'GrooveKart') {
        window.location = '/static/groovekart';
      } else if (linkType == 'GrooveWebinar') {
        window.location = '/groovewebinar/';
      } else if (linkType == this.$t('frontend_gd_dashboard_groove_support')) {
        var win = window.open(this.$t('official_link_support'), '_blank');
        win.focus();
      } else if (linkType == this.$t('frontend_gd_dashboard_partner')) {
        this.showPartnerProgram = 1;
      } else if (
        linkType == this.$t('frontend_gd_dashboard_account_settings')
      ) {
        this.editUserDetails();
      } else if (linkType == 'GrooveAffiliate') {
        window.location = '/groovesell/affiliates-dashboard';
      }
    },

    // quicklink popup settings
    quickLinkOpen() {
      this.$root.$emit('modal-open', 'quickLink');
    },

    closequicklinks() {
      const metaInfo = JSON.parse(localStorage.getItem('user'));
      this.$store.commit('setQuickLinks', metaInfo.metadata);
      this.$root.$emit('modal-close', 'quickLink');
    },

    submitquickLinks() {
      axios
        .post(
          `groovedigital/auth/users/update-meta/${this.$store.state.user.id}`,
          { quickLinks: this.quickLinks, insights: this.insights }
        )
        .then((response) => {
          const userResponse = response.data;
          const meta = {
            quickLinks: userResponse.quickLinks,
            insights: userResponse.insights,
          };
          this.$store.commit('setQuickLinks', meta);
        });
      this.$root.$emit('modal-close', 'quickLink');
    },

    activeNumber(num) {
      let active;
      if (num == 0) {
        active = 0;
      } else if (num == 1) {
        active = 1;
      } else if (num == 7) {
        active = 2;
      } else if (num == 14) {
        active = 3;
      } else if (num == 30) {
        active = 4;
      }
      return active;
    },

    /* groovePage data */
    async groovePage(num) {
      this.getGroovePage(num);
    },
    async getGroovePage(number) {
      this.hasGroovepagesDataLoaded = 0;
      let count = 0;
      if (number == 1) {
        count = 1;
      }
      if (number == 7) {
        count = 7;
      }
      if (number == 14) {
        count = 14;
      }
      if (number == 30) {
        count = 30;
      }
      this.pageErr = false;
      await axios
        .get(`groovepages/sites/analytics/recent-site/days/${count}`)
        .then((response) => {
          this.pageErr = false;
          this.formatPageStats(response.data.data);
          this.pageActive = this.activeNumber(number);
          this.hasUsedGroovepages = response.data.has_used;

          if (response.data.data.length > 0) {
            this.hasGroovepagesData = 1;
          } else {
            this.hasGroovepagesData = 0;
          }
          this.hasGroovepagesDataLoaded = 1;
        })
        .catch((err) => {
          this.pageErr = false;
          this.hasUsedGroovepages = 0;
          this.hasGroovepagesDataLoaded = 1;
        });
    },

    formatPageStats(stats) {
      const formatted = [];
      stats.forEach((site) => {
        site['Site Name'] = site.name;
        site.Visits = 0;
        site['Unique Visitors'] = 0;
        site['Bounce Rate'] = 0;
        for (const date in site.analytics) {
          site.Visits += site.analytics[date].nb_visits || 0;
          site['Unique Visitors'] += site.analytics[date].nb_uniq_visitors || 0;
          site['Bounce Rate'] +=
            parseInt(site.analytics[date].bounce_rate) || 0;
        }
        site['Bounce Rate'] = `${site['Bounce Rate']}%`;
        formatted.push(site);
      });

      this.pageData = formatted;
    },

    // video Info
    async grooveVideo(num) {
      this.getVideoData(num);
    },
    async getVideoData(number) {
      this.hasGroovevideoDataLoaded = 0;
      let fromDate = moment();
      let toDate = moment();
      if (number == 1) {
        fromDate = moment().subtract(1, 'days');
        toDate = moment().subtract(1, 'days');
      }
      if (number == 7) {
        fromDate = moment().subtract(6, 'days');
      }
      if (number == 14) {
        fromDate = moment().subtract(13, 'days');
      }
      if (number == 30) {
        fromDate = moment().subtract(30, 'days');
      }

      await axios
        .post('groovevideo/charts/dashboard', {
          chart_value: 'plays',
          diffDays: number,
          videoid: 0,
          fromDate: fromDate.format('MM/DD/YYYY'),
          toDate: toDate.format('MM/DD/YYYY'),
        })
        .then((response) => {
          this.videosData = response.data.videos;
          this.vidActive = this.activeNumber(number);

          this.hasUsedGroovevideo = response.data.has_used;

          if (response.data.videos.length > 0) {
            this.hasGroovevideoData = 1;
          } else {
            this.hasGroovevideoData = 0;
          }
          this.hasGroovevideoDataLoaded = 1;
        })
        .catch((err) => {
          this.videoErr = false;
          this.vidActive = this.activeNumber(number);
          this.hasUsedGroovevideo = 0;
          this.hasGroovevideoDataLoaded = 1;
        });
    },

    // grooveSell Info
    async grooveSell(num) {
      this.getGrooveSell(num);
    },

    async getGrooveSell(number) {
      this.hasGroovesellDataLoaded = 0;
      let fromDate = moment();
      let toDate = moment();
      if (number == 1) {
        fromDate = moment().subtract(1, 'days');
        toDate = moment().subtract(1, 'days');
      }
      if (number == 7) {
        fromDate = moment().subtract(6, 'days');
      }
      if (number == 14) {
        fromDate = moment().subtract(13, 'days');
      }
      if (number == 30) {
        fromDate = moment().subtract(30, 'days');
      }

      await axios
        .post('groovesell/charts/get-vendor-report', {
          chart_value: 'revenue',
          affiliates: [{ id: 0, name: 'All' }],
          diffDays: number,
          fromDate: fromDate.format('MM/DD/YYYY'),
          toDate: toDate.format('MM/DD/YYYY'),
          funnels: [],
          selected_tab: 'total',
        })
        .then((response) => {
          this.sellData = response.data;
          this.grooveSellSeries[0].data = response.data.chart_values;
          this.hasUsedGroovesell = response.data.has_used;

          if (
            response.data.selected_tab_data.sales_count > 0 ||
            response.data.selected_tab_data.rebills_count > 0 ||
            response.data.selected_tab_data.refunded_count > 0 ||
            response.data.selected_tab_data.revenue > 0 ||
            response.data.selected_tab_data.profit > 0
          ) {
            this.hasGroovesellData = 1;
          } else {
            this.hasGroovesellData = 0;
          }

          this.sellActive = this.activeNumber(number);
          this.hasGroovesellDataLoaded = 1;
          this.chartsKey++;
        })
        .catch((err) => {
          this.sellErr = false;
          this.sellActive = this.activeNumber(number);
          this.hasUsedGroovesell = 0;
          this.hasGroovesellDataLoaded = 1;
          this.chartsKey++;
        });
    },

    // grooveMail Info
    async grooveMail(num) {
      this.getGrooveMail(num);
    },

    async getGrooveMail(number) {
      this.hasGroovemailDataLoaded = 0;
      const fromdate = moment().subtract(number, 'days').format('L');
      let {toDate} = this;
      if(number == 1) {
        toDate = moment().subtract(1, 'days').format('L');
      }
      await axios
        .get('groovemail/campaign', {
          params: {
            startDate: fromdate,
            endDate: toDate,
            limit: 25,
          },
        })
        .then((response) => {
          this.mailData = response.data.data;
          this.mailActive = this.activeNumber(number);

          this.hasUsedGroovemail = response.data.has_used;

          if (response.data.data.length > 0) {
            this.hasGroovemailData = 1;
          } else {
            this.hasGroovemailData = 0;
          }
          this.hasGroovemailDataLoaded = 1;
        })
        .catch((err) => {
          this.mailErr = false;
          this.mailActive = this.activeNumber(number);
          this.hasUsedGroovemail = 0;
          this.hasGroovemailDataLoaded = 1;
        });
    },

    // groovemember Info
    async grooveMember(num) {
      this.getGrooveMember(num);
    },

    async getGrooveMember(number) {
      this.hasGroovememberDataLoaded = 0;
      let fromDate = moment();
      let toDate = moment();
      if (number == 1) {
        fromDate = moment().subtract(1, 'days');
        toDate = moment().subtract(1, 'days');
      }
      if (number == 7) {
        fromDate = moment().subtract(6, 'days');
      }
      if (number == 14) {
        fromDate = moment().subtract(13, 'days');
      }
      if (number == 30) {
        fromDate = moment().subtract(30, 'days');
      }

      await axios
        .post('groovemember/charts/get-members-report', {
          diffDays: number,
          fromDate: fromDate.format('MM/DD/YYYY'),
          toDate: toDate.format('MM/DD/YYYY'),
          chart_value: 'total_members',
          membership: 0,
        })
        .then((response) => {
          this.memberData = response.data.selected_tab_data;
          this.grooveMemSeriers[0].data = response.data.chart_values;
          this.memActive = this.activeNumber(number);

          this.hasUsedGroovemember = response.data.has_used;

          if (
            response.data.selected_tab_data.total_members_count > 0 ||
            response.data.selected_tab_data.active_members_count > 0 ||
            response.data.selected_tab_data.inactive_members_count > 0
          ) {
            this.hasGroovememberData = 1;
          } else {
            this.hasGroovememberData = 0;
          }
          this.hasGroovememberDataLoaded = 1;
          this.chartsKey++;
        })
        .catch((err) => {
          this.memmberErr = false;
          this.memActive = this.activeNumber(number);
          this.hasUsedGroovemember = 0;
          this.hasGroovememberDataLoaded = 1;
          this.chartsKey++;
        });
    },

    // grooveaffiliates Info
    async groovePromo(num) {
      this.getGroovePromos(num);
    },

    async getGroovePromos(number) {
      this.hasGrooveAffiliateDataLoaded = 0;
      let fromDate = moment();
      let toDate = moment();
      if (number == 1) {
        fromDate = moment().subtract(1, 'days');
        toDate = moment().subtract(1, 'days');
      }
      if (number == 7) {
        fromDate = moment().subtract(6, 'days');
      }
      if (number == 14) {
        fromDate = moment().subtract(13, 'days');
      }
      if (number == 30) {
        fromDate = moment().subtract(30, 'days');
      }

      await axios
        .get('groovesell/main-promo-dashboard-stats', {
          params: {
            diffDays: number,
            fromDate: fromDate.format('MM/DD/YYYY'),
            toDate: toDate.format('MM/DD/YYYY'),
          },
        })
        .then((response) => {
          this.promoData = response.data.data;
          this.promoActive = this.activeNumber(number);

          this.hasUsedGrooveaffiliate = response.data.has_used;
          this.hasGrooveaffiliateData = response.data.has_data;
          this.hasGrooveAffiliateDataLoaded = 1;
        })
        .catch((err) => {
          this.promoErr = false;
          this.promoActive = this.activeNumber(number);
          this.hasUsedGrooveaffiliate = 0;
          this.hasGrooveAffiliateDataLoaded = 1;
        });
    },

    load() {
      this.iframeHeight = `${
        window.innerHeight -
        this.$refs.iframeContainer.getBoundingClientRect().top
      }px`;
    },

    oniFrameload() {
        this.isUpgradeAdLoading = false;
    },

    goToPartnerProgram() {
      window.open('https://groovejv.com', '_blank');
    },
    redirectDropdown(url) {
      window.location = url;
    },
    toggleDropdown(id) {
      this.dropdownOpen = this.dropdownOpen === id ? null : id
    },
    openVideoWalkthrough() {
      this.videoWalkthroughOpen = true;
    },
  },
};
</script>

<style lang="scss">
// TODO - Add new colors in Tailwind config
@layer components {
  .bg-new-blue { background-color: #3666fd; }
  .text-new-blue { color: #3666fd; }
  .border-new-blue { border-color: #3666fd; }
}

.col-dashboard {
  position: relative;
  overflow: hidden;
}

.col-width { @apply w-full; }
.col-height { height: 520px; }

@screen lg {
  .col-width {
    width: 49%;
  }
}

.col-width .half-circle-spinner,
.vld-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vld-overlay {
  width: 100%;
  height: 100%;
}

.rightDots {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, 0);
}

.text-color {
  color: #191d3a;
}

.recentList div:last-child {
  border-bottom: 0;
}

.active a {
  border-color: #cccedc;
}

.active:first-child a {
  border-color: #ea3d5e;
  background: #fff;
}

.borderBottom {
  border-bottom: 1px solid #dcdcea;
}

.borderRight {
  border-right: 1px solid #dcdcea;
}

.borderRadius {
  border-radius: 6px;
}

.btn-cta-affiliate {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #3666fd;

  > .text {
    line-height: 0.9rem;

    > span {
      font-size: 10px;
    }
  }

  &:hover, &:focus {
    @apply text-white;
    background-color: #3666fd;
  }

}
</style>

<style lang="scss">
.w-screen-overwrite {
  position: relative;
  width: calc(100vw - 72px);
  left: 50%;
  margin-left: calc(-50vw + 36px);
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.aspect-ratio-square {
  padding-top: 100%;
}
.aspect-ratio-169 {
  padding-top: 56.25%;
}
.aspect-ratio-43 {
  padding-top: 75%;
}
.aspect-ratio-219 {
  padding-top: 42.86%;
}

.quicklink-wrapper {
  .quicklink-list {
    .quicklink-btn {
      display: none;
      transform: rotate(90deg);
    }
    &:hover {
      .quicklink-btn,
      .quicklink-dropdown {
        display: block;
      }
    }
  }
  .quicklink-dropdown {
    min-width: 200px;
    box-shadow: 0 3px 10px #cccccc;
    z-index: 1;
    display: none;
  }
  .h-32:first-child {
    .quicklink-dropdown {
      min-width: 140px;
    }
  }
}

svg .membership-xl{
  fill: none;
  stroke: #989ab3;
  stroke-width: 1.5;
}
@keyframes ps-pulse2 {0% {transform: scale(0.95);box-shadow: 0 0 0 0 #ea3d5e;}70% {transform: scale(1);box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);}100% {transform: scale(0.95);box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);}}
.video-walkthrough-tooltip{
  background-color: rgba(25, 29, 58, 1)!important;
  color: #ddd!important;
  padding: 4px 8px!important;
  border-radius: 5px!important;
  top: 2px!important;
  margin-right: 10px!important;
  font-size: 0.9rem!important;

  &::before {
    content: '';
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid rgba(25, 29, 58, 1);
    border-right: none;
  }
}
</style>

