<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPageLoader"
      :color="loaderColor"
    ></loading>
    <div v-if="open" class="fixed inset-0 z-40 bg-grey-50 overflow-auto">
      <div class="p-4 bg-white shadow relative z-10">
        <div class="container mx-auto flex justify-between items-center">
          <div class="text-lg text-dark font-bold">
            {{ title }}
          </div>
          <div class="flex">
            <gd-button @click="close" size="auto" class="px-5 h-10"
              >{{ $t('frontend_close') }}
            </gd-button>
          </div>
        </div>
      </div>
      <div
        class="overflow-hidden h-full"
        style="max-height: calc(100vh - 72px)"
        ref="iframeContainer"
      >
        <iframe
          frameborder="0"
          :src="url"
          class="w-full min-h-screen"
          :style="{ height: iframeHeight }"
          @load="load"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import GdButton from '@groovepages/gd-ui-kit/components/Button';
import themeConfig from '@/theme/themeConfig';

export default {
  components: {
    GdButton,
  },
  props: ['open', 'url', 'title'],
  watch: {
    open(newVal) {
      if (newVal) {
        this.init();
      }
    },
  },
  data() {
    return {
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
      iframeHeight:'200px',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    showLoader() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },
    hideLoader() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },
    load() {
      this.iframeHeight = `${
        window.innerHeight -
        this.$refs.iframeContainer.getBoundingClientRect().top
      }px`;
    },
  },
};
</script>
